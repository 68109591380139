import React, {useRef} from 'react';
import {
  Box,
  Tabs,
  Paper,
  Stack,
  Select,
  Divider,
  useTheme,
  MenuItem,
  Container,
  InputLabel,
  Typography,
  FormControl,
  tabsClasses,
} from '@mui/material';
import PropTypes from 'prop-types';
import {grey} from '@mui/material/colors';
import {styled} from '@mui/material/styles';
import {useHistory} from 'react-router-dom';
import Tab, {tabClasses} from '@mui/material/Tab';
import {
  NoResults,
  SearchFilter,
  ErrorBoundary,
  themeColorMode,
  BackgroundPaper,
  RequestErrorLoader,
  SearchResultsCounter,
} from 'mui-core';

const MenuContainer = styled(Paper)(({theme}) => ({
  paddingTop: 0,
  boxShadow: 'none',
  background: 'transparent',
  '& .Mui-selected': {
    color: 'white !important',
    background: theme.palette.primary.main,
  },
}));

const StyledTabs = styled(Tabs)(({theme, centeredTab}) =>
  centeredTab
    ? {
        padding: '24px 0px',
        // [theme.breakpoints.only('xs')]: {
        //   height: 40,
        //   minHeight: 40,
        // },
        // [theme.breakpoints.only('sm')]: {
        //   height: 45,
        //   minHeight: 45,
        // },
        [`& .${tabsClasses.indicator}`]: {
          height: '100%',
          borderRadius: 40,
          zIndex: 1,
        },
        [`& .${tabsClasses.flexContainer}`]: {
          position: 'relative',
          zIndex: 2,
          justifyContent: 'center',
        },
      }
    : {
        minHeight: '32px',
        borderRadius: '16px',
        border: `1px solid ${grey[400]}`,
        width: {xs: 'auto', sm: 'fit-content'},
        transition: 'transform 0.3s ease-in-out',
        [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': {opacity: 0.3},
        },
      },
);

const StyledTab = styled(Tab)(({theme, index, centeredTab}) =>
  centeredTab
    ? {
        borderRadius: 40,
        border: `1px solid ${
          theme.palette.mode === 'light'
            ? theme.palette.shadeyBlack.main
            : theme.palette.primary.light
        }`,
        marginRight: 24,
        minWidth: 250,
        fontWeight: 500,
        height: 60,
        fontSize: 16,
        textTransform: 'capitalize',
        color:
          theme.palette.mode === 'light'
            ? theme.palette.shadeyBlack.main
            : theme.palette.secondary.midGray,
        [theme.breakpoints.only('xs')]: {
          minWidth: 100,
          fontSize: 14,
          height: 40,
          minHeight: 40,
        },
        [theme.breakpoints.only('sm')]: {
          minWidth: 160,
          fontSize: 15,
          height: 45,
          minHeight: 45,
        },
        [`&.${tabClasses.selected}`]: {
          backgroundColor: theme.palette.shadeyBlack.main,
          color: theme.palette.getContrastText(theme.palette.shadeyBlack.main),
        },
        '&:last-child': {
          marginRight: 0,
        },
      }
    : {
        minHeight: '40px',
        minWidth: '180px',
        padding: {xs: '0px 16px', sm: '0px 22px'},
        borderLeft: index !== 0 && `1px solid ${grey[400]}`,
      },
);

const LayoutContainer = ({
  banner,
  children,
  onChange,
  typeOfUi,
  emptyMsg,
  activeTab,
  showSearch,
  filterForm,
  title = '',
  tabArr = [],
  dataSet = {},
  subTitle = '',
  showFilterBtn,
  sortProps = {},
  nameOfComponent,
  showSort = true,
  searchProps = {},
  loadingTitle = '',
  additionalContent,
  showDivider = true,
  centeredTab = false,
  childrenBGcolor = '',
  disableBGcolor = false,
  showResultCount = true,
  searchResultProps = {},
  enableFullWidth = false,
  overrideNoDataContainer,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const menuContainerRef = useRef();
  const {
    location: {pathname},
  } = history || {};

  // Extract the active tab from the pathname
  let loadedTab = pathname?.replace('/', '');

  if (loadedTab?.includes('/')) {
    loadedTab = loadedTab?.split('/')[0];
    if (loadedTab === 'whos-hiring') {
      loadedTab = 'companies';
    }
  } else if (loadedTab === 'whos-hiring') {
    loadedTab = 'companies';
  }

  // Extract properties from searchProps and sortProps for easy reference
  const {
    onFinish,
    filters = {},
    onDrawerOpen,
    onFilterReset,
    name = 'title',
    searchPlaceholder = 'Placeholder please...',
  } = searchProps || {};

  const {request, data = []} = dataSet || {};
  const {sort = '', onSortChange, sortArr = []} = sortProps || {};
  const {totalCount = 0, recordsCount = 0} = searchResultProps || {};
  const isDataAvailable = Array.isArray(data) && data.length > 0;
  const isTabArrAvailable = Array.isArray(tabArr) && tabArr.length > 0;
  const isSortArrAvailable = Array.isArray(sortArr) && sortArr.length > 0;

  const BGcolor = childrenBGcolor || theme.palette.purplebg4perc.main;

  // Renders the tabs and associated content
  const renderTabs = () => {
    const currentChild =
      typeof activeTab === 'number'
        ? tabArr?.[activeTab]?.component
        : tabArr?.find(itm => itm.key === activeTab)?.component;

    return (
      <MenuContainer id='mui-core-layout-container' ref={menuContainerRef}>
        <Stack>
          {/* Additional content section */}
          {additionalContent && <Box>{additionalContent}</Box>}

          {/* Render tabs if available */}
          {isTabArrAvailable && (
            <Stack
              py={centeredTab ? 0 : 3}
              justifyContent={centeredTab ? 'center' : 'left'}
              width={centeredTab ? '100%' : {xs: 'auto', sm: 'fit-content'}}>
              <StyledTabs
                value={activeTab}
                onChange={onChange}
                scrollButtons='auto'
                variant='scrollable'
                centeredTab={centeredTab}
                allowScrollButtonsMobile>
                {tabArr.map((tab, index) => (
                  <StyledTab
                    id={tab.key}
                    index={index}
                    key={tab.key}
                    value={tab.key}
                    label={tab.tabName}
                    centeredTab={centeredTab}
                  />
                ))}
              </StyledTabs>
            </Stack>
          )}

          {/* Title, subtitle, search, and sorting */}
          <Stack spacing={2.5}>
            <Box>
              {title && (
                <Typography variant='h3' color='text.primary'>
                  {title}
                </Typography>
              )}
              {subTitle && (
                <Typography
                  mt={1}
                  component='p'
                  variant='bodyXL'
                  color={themeColorMode(theme, grey[700], 'white')}>
                  {subTitle}
                </Typography>
              )}
            </Box>
            {showDivider && <Divider />}
            <SearchFilter
              name={name}
              filters={filters}
              onReset={onFilterReset}
              showSearch={showSearch}
              onDrawerOpen={onDrawerOpen}
              showFilterBtn={showFilterBtn}
              onSearch={value => onFinish(value)}
              searchPlaceholder={searchPlaceholder}
            />
          </Stack>

          {/* Render result count and sorting controls */}
          {isDataAvailable &&
            (showResultCount || (showSort && isSortArrAvailable)) && (
              <Stack
                direction='row'
                justifyContent={showResultCount ? 'space-between' : 'flex-end'}>
                {showResultCount && (
                  <SearchResultsCounter
                    request={request}
                    totalResults={totalCount}
                    currentLength={recordsCount}
                  />
                )}
                {showSort && isSortArrAvailable && (
                  <SortControl
                    sort={sort}
                    sortArr={sortArr}
                    onSortChange={onSortChange}
                  />
                )}
              </Stack>
            )}

          {/* Error loader and dynamic child content */}
          <RequestErrorLoader
            fullScreen
            body={dataSet}
            overrideNoDataContainer={
              overrideNoDataContainer ? (
                overrideNoDataContainer
              ) : (
                <Stack width='100%' my={3}>
                  <NoResults
                    title='Oops! No Results found'
                    subTitle={
                      emptyMsg
                        ? emptyMsg
                        : `We couldn't find any ${nameOfComponent}. Please modify your search and try again.`
                    }
                  />
                </Stack>
              )
            }
            title={
              loadingTitle
                ? loadingTitle
                : loadedTab
                ? `Gathering the best ${loadedTab} for you... Hang in there!`
                : 'Loading...'
            }
          />
          {currentChild}
        </Stack>
        {/* Render filter form */}
        {filterForm}
      </MenuContainer>
    );
  };

  return (
    <BackgroundPaper background={disableBGcolor ? 'transparent' : BGcolor}>
      <ErrorBoundary nameOfComponent={nameOfComponent} typeOfUi={typeOfUi}>
        {/* Optional banner section */}
        {banner || null}
        <Container
          maxWidth={enableFullWidth ? false : 'xl'}
          disableGutters={enableFullWidth ? true : false}>
          {renderTabs()}
          {/* Children content */}
          {children && <Box pt={2}>{children}</Box>}
        </Container>
      </ErrorBoundary>
    </BackgroundPaper>
  );
};

// SortControl component for sorting options
const SortControl = ({sort, sortArr, onSortChange}) => (
  <Stack mt={1}>
    <FormControl>
      <InputLabel id='sort-by-label' htmlFor='Sort by'>
        Sort by
      </InputLabel>
      <Select
        size='small'
        value={sort}
        label='Sort by'
        defaultValue={sort}
        aria-label='Sort by'
        labelId='sort-by-label'
        inputProps={{id: 'Sort by'}}
        sx={{
          '.MuiSelect-nativeInput': {visibility: 'hidden'},
          minWidth: '120px',
          mb: {xs: 1},
        }}
        onChange={event => onSortChange(event.target.value)}>
        {sortArr.map(({value, title}, idx) => (
          <MenuItem value={value} key={idx}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Stack>
);

SortControl.propTypes = {
  sort: PropTypes.string,
  sortArr: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  onSortChange: PropTypes.func,
};

LayoutContainer.propTypes = {
  title: PropTypes.string, // Title string
  children: PropTypes.node, // React children
  dataSet: PropTypes.object, // Object of data
  emptyMsg: PropTypes.string, // emptyMsg string
  subTitle: PropTypes.string, // Subtitle string
  typeOfUi: PropTypes.string.isRequired, // typeOfUi string
  centeredTab: PropTypes.bool, // Boolean to center the tab
  sortProps: PropTypes.object, // Object for sorting properties
  showSearch: PropTypes.bool, // Boolean to show or hide search
  searchProps: PropTypes.object, // Object for search properties
  showDivider: PropTypes.bool, // Boolean to show or hide divider
  showSort: PropTypes.bool, // Boolean to show or hide sort feature
  loadingTitle: PropTypes.string, // String for loading state title
  tabArr: PropTypes.arrayOf(PropTypes.object), // Array of tab names
  childrenBGcolor: PropTypes.string, // Background color for children
  onChange: PropTypes.func, // Function for tab change event or similar
  disableBGcolor: PropTypes.bool, // Boolean to disable background color
  nameOfComponent: PropTypes.string.isRequired, // nameOfComponent string
  showFilterBtn: PropTypes.bool, // Boolean to show or hide filter button
  additionalContent: PropTypes.node, // Additional content as a React node
  showResultCount: PropTypes.bool, // Boolean to show or hide result count
  banner: PropTypes.node, // Assuming banner is a React component or element
  filterForm: PropTypes.node, // Assuming this is a form component or element
  searchResultProps: PropTypes.object, // Object for search result properties
  overrideNoDataContainer: PropTypes.node, // empty container component or node
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Can be a string or a number
};
export default LayoutContainer;
