// hooks
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
// components
import {
  Box,
  Grow,
  List,
  ListItem,
  TextField,
  IconButton,
  FormControl,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  ListItemButton,
  FormControlLabel,
  ClickAwayListener,
} from '@mui/material';
import {Search, DeleteOutline} from '@mui/icons-material';
// redux
import {
  addGlobalSearchItem,
  getGlobalSearchItems,
  getGlobalSearchHistory,
  deleteGlobalSearchItem,
} from 'redux/modules/general';
// utils
import {debounce} from 'lodash';
import {styled} from '@mui/material/styles';
import {themeColorMode} from 'mui-core/utils';
import {queryStringParse, getModuleBasePath} from 'core/utils';

const StyledSearchField = styled(TextField)(
  ({theme, isdropdownopen, mobile}) => {
    return {
      marginLeft: '16px',
      width: '100%',
      zIndex: 2,
      position: 'relative',
      backgroundColor: themeColorMode(theme, 'white', '#444'),
      borderRadius: isdropdownopen ? '6px 6px 0px 0px' : 0,
      '& .MuiOutlinedInput-root': {
        fontSize: 14,
      },
      '& fieldset': {
        borderColor:
          isdropdownopen || mobile
            ? 'transparent !important'
            : themeColorMode(theme, theme.palette.primary.light, '#555'),
      },
      '& input::placeholder': {
        color: themeColorMode(theme, theme.palette.primary.dark, '#bbb'),
        opacity: themeColorMode(theme, 0.6, 0.7),
      },
    };
  },
);

const SearchBar = ({mobile, toggleMobileSwipableSearch}) => {
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  // states
  const [openDrop, setOpenDrop] = useState();
  const [searchKey, setSearchKey] = useState('');
  //selectors
  const globalSearchHistory = useSelector(getGlobalSearchHistory) || [];
  // utils
  const {
    location: {search},
  } = history;
  const {query = ''} = queryStringParse(search);
  const showDropDown =
    globalSearchHistory &&
    Array.isArray(globalSearchHistory) &&
    globalSearchHistory.length > 0 &&
    !searchKey;
  const decodedQuery = decodeURIComponent(query);

  useEffect(() => {
    setSearchKey(decodedQuery);
    if (mobile) {
      setOpenDrop(true);
    }
    return () => {
      setSearchKey();
    };
  }, [decodedQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // get search history from local storage
    dispatch(getGlobalSearchItems());
  }, [mobile]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigateToSearch = value => {
    dispatch(addGlobalSearchItem(value));
    // const BasePath = getModuleBasePath('search');
    // Default to program tab
    history.push(`/search/programs?query=${value}`);
    setSearchKey(value);
    setOpenDrop(false);
    if (mobile) {
      toggleMobileSwipableSearch(false);
    }
  };

  const onSearch = e => {
    if (e.keyCode && e.keyCode == 13) {
      if (searchKey) {
        navigateToSearch(e.target.value);
      } else {
        // If no query redirect
        history.push(`/`);
      }
    } else {
      setSearchKey(e.target.value);
    }
  };

  const onSelect = debounce(data => navigateToSearch(data), 10);

  const deleteSearchHistory = title => {
    dispatch(deleteGlobalSearchItem(title));
  };

  return (
    <ClickAwayListener onClickAway={() => setOpenDrop(false)}>
      <Box
        onClick={() => setOpenDrop(true)}
        sx={{
          position: 'relative',
          maxWidth: 600,
          minWidth: mobile ? '100%' : 200,
          width: '80%',
          borderRadius: openDrop && showDropDown ? '6px 6px 0px 0px' : 0,
          boxShadow: theme =>
            openDrop && showDropDown
              ? themeColorMode(theme, '0px 0px 5px 2px #d9d9d9', 'none')
              : 'none',
        }}>
        <FormControl fullWidth>
          <FormControlLabel
            sx={{marginRight: 0}}
            control={
              <StyledSearchField
                aria-invalid='true'
                isdropdownopen={openDrop && showDropDown}
                variant='outlined'
                value={searchKey}
                autoFocus={mobile}
                color='primary'
                mobile={mobile}
                size={mobile ? 'medium' : 'small'}
                onChange={onSearch}
                onKeyUp={onSearch}
                aria-label='Search for programs, jobs or careers'
                placeholder='Search for programs, jobs or careers'
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size='small'
                      aria-label='Search'
                      onClick={() => searchKey && navigateToSearch(searchKey)}>
                      <Search color='primary' />
                    </IconButton>
                  ),
                }}
              />
            }
          />
        </FormControl>
        <Box
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            marginTop: '-1px',
          }}>
          {showDropDown && openDrop && (
            <Grow
              in={openDrop}
              sx={{
                backgroundColor: theme =>
                  themeColorMode(theme, 'white', '#444'),
                borderRadius: openDrop && showDropDown ? '0px 0px 6px 6px' : 0,
                boxShadow: theme =>
                  openDrop && showDropDown
                    ? themeColorMode(theme, '0px 0px 5px 2px #d9d9d9', 'none')
                    : 'none',
              }}
              style={{transformOrigin: '0 0 0'}}
              {...(openDrop ? {timeout: 200} : {timeout: 100})}>
              <Box
                sx={{
                  borderRadius:
                    openDrop && showDropDown ? '0px 0px 6px 6px' : 0,
                  backgroundColor: theme =>
                    themeColorMode(theme, 'white', '#444'),
                }}>
                <List
                  dense
                  sx={{
                    width: '100%',
                    bgcolor: 'transparent',
                    maxHeight: '80vh',
                  }}
                  component='nav'
                  aria-labelledby='nested-list-subheader'
                  subheader={
                    <ListSubheader
                      sx={{bgcolor: 'transparent'}}
                      component='div'
                      id='nested-list-subheader'>
                      Recent Searches
                    </ListSubheader>
                  }>
                  {globalSearchHistory.map(key => (
                    <ListItem
                      key={key}
                      secondaryAction={
                        <IconButton
                          onClick={() => deleteSearchHistory(key)}
                          edge='end'
                          aria-label='comments'
                          size='small'>
                          <DeleteOutline fontSize='13' />
                        </IconButton>
                      }
                      disablePadding>
                      <ListItemButton onClick={() => onSelect(key)}>
                        <ListItemIcon>
                          <Search />
                        </ListItemIcon>
                        <ListItemText primary={key} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grow>
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default SearchBar;
