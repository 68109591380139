import React from 'react';
import {Grid, Button, CareerCard, ErrorBoundary} from 'mui-core';
import {Box, useTheme, useMediaQuery} from '@mui/material';
import {
  PaidOutlined,
  DiamondOutlined,
  TungstenOutlined,
  BusinessCenterOutlined,
} from '@mui/icons-material';
import {exploreCareer} from 'data/exploreCareer.json';

const CareerList = ({filters, reqParams, refetchData, careerRank = {}}) => {
  const theme = useTheme();
  const {data = {}, error, request: careerRankRequest} = careerRank || {};
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    data: careerRankList = [],
    page_details: careerRankPageDetails = {},
  } = data;
  const recordsCount = careerRankList.length || 0;
  const {loadingLabel, loadMoreLabel} = exploreCareer;
  const totalCount = careerRankPageDetails?.total_results || 0;
  const hideLoadMore =
    (careerRankPageDetails &&
      reqParams?.page >= careerRankPageDetails?.no_of_pages &&
      !careerRankRequest &&
      !error) ||
    recordsCount === totalCount ||
    totalCount <= 16;

  const loadMoreCareers = () => {
    const newPage = reqParams.page + 1;
    const Obj = Object.assign({}, reqParams, {
      page: newPage,
    });
    refetchData(Obj, filters, true);
  };

  return (
    <>
      {careerRankList?.length > 0 && (
        <>
          <Grid
            container
            columnSpacing={{xs: 2, sm: 2}}
            rowSpacing={{xs: 2, sm: 2, md: 3, lg: 4}}
            justifyContent={isMobile ? 'center' : 'left'}>
            {careerRankList.map(careerData => (
              <Grid
                item
                lg={3}
                xs={12}
                sm='auto'
                justifyContent='center'
                sx={{
                  flexBasis: 'fit-content',
                  textAlign: {xs: 'center', sm: 'left'},
                }}
                key={careerData.occupation_onnet}>
                <CareerCard
                  width={320}
                  target='_self'
                  enableNavigation
                  imageHeight={180}
                  data={careerData}
                  infoItems={[
                    {
                      label: 'Jobs',
                      Icon: BusinessCenterOutlined,
                      count: careerData?.metrics?.jobs,
                    },
                    {
                      label: 'Programs',
                      Icon: DiamondOutlined,
                      count: careerData?.metrics?.programs,
                    },
                    {
                      label: 'Apprenticeships',
                      Icon: TungstenOutlined,
                      count: careerData?.metrics?.apprenticeships,
                    },
                    {
                      label: 'Salary',
                      Icon: PaidOutlined,
                      count: careerData?.metrics?.salary,
                    },
                  ]}
                />
              </Grid>
            ))}
          </Grid>
          {!hideLoadMore && (
            <Box
              py={3}
              display='flex'
              alignContent='center'
              justifyContent='center'>
              <Button
                size='large'
                color='primary'
                variant='outlined'
                sx={{
                  padding: '8px 54px',
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.grayLight.main
                      : theme.palette.primary.darker,
                }}
                onClick={loadMoreCareers}>
                {careerRankRequest ? loadingLabel : loadMoreLabel}
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CareerList;
