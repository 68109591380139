import Tabs, {tabsClasses} from '@mui/material/Tabs';
import Tab, {tabClasses} from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {Link} from 'react-router-dom';
import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Toolbar,
  Menu,
  MenuItem,
  Stack,
  ButtonBase,
} from '@mui/material';
import {themeColorMode} from 'mui-core/utils';
import {styled} from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import {useSelector} from 'react-redux';
import {getInstanceVersion} from 'redux/modules/instance';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const menuHeight = 40;

const movedUpStyles = (theme, movedUp) => {
  // if (movedUp)
  return {
    position: 'fixed',
    top: theme.mixins.toolbar,
    width: '100%',
    left: 0,
  };
  // else return {};
};

const StyledPaper = styled(Paper)(({theme, menuBarOnHide, movedUp}) => ({
  backgroundColor: themeColorMode(
    theme,
    theme.palette.background.paper,
    theme.palette.darkGray.main,
  ),
  backgroundImage: 'none',
  position: 'relative',
  zIndex: 1000,
  boxShadow:
    !menuBarOnHide || movedUp
      ? `0px 1px 0px 1px ${theme.palette.divider}`
      : 'none',
  ...movedUpStyles(theme, movedUp),
}));

const StyledTabs = styled(Tabs)(({theme}) => ({
  [`& .${tabsClasses.indicator}`]: {
    height: 'calc(100% - 7px)',
    transition: 'none',
    top: 3,
    borderRadius: '4px',
    background: `linear-gradient(180deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
    // backgroundColor: theme =>
    //   themeColorMode(theme, 'primary.dark', 'primary.light'),
  },
}));

const StyledTab = styled(Tab)(({theme}) => ({
  marginRight: theme.spacing(2),
  minHeight: menuHeight,
  minWidth: '60px',
  height: menuHeight,
  zIndex: 3,
  [theme.breakpoints.only('xs')]: {
    marginRight: 0,
  },
  [`&.${tabClasses.selected}`]: {
    color: '#fff',
  },
}));
const StyledTabText = styled(Typography)(({theme}) => ({
  [theme.breakpoints.only('xs')]: {
    fontSize: 11,
  },
}));

const MenuDropDown = ({data, label, base, selected}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{minHeight: menuHeight, display: 'flex', alignItems: 'center'}}>
      <Stack
        onClick={handleClick}
        aria-controls={'basic-menu'}
        disableRipple
        component={ButtonBase}
        sx={{
          textTransform: 'capitalize',
          minHeight: menuHeight,
        }}
        alignItems={'center'}
        flexDirection={'row'}>
        <StyledTabText
          variant='subtitle2'
          sx={{color: 'inherit'}}
          fontSize={14}
          textTransform={'initial'}>
          {label}
        </StyledTabText>
        <KeyboardArrowDownRoundedIcon fontSize='small' />
      </Stack>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        PaperProps={{
          sx: {mt: 1, minWidth: 140, marginLeft: '-21px'},
          elevation: 2,
        }}
        open={open}
        onClose={handleClose}>
        {Array.isArray(data) &&
          data.map(({label, path, key}) => (
            <MenuItem
              to={`${base}${path}`}
              sx={{
                ...(selected === key
                  ? {color: theme => theme.palette.primary.main}
                  : {}),
              }}
              component={Link}
              key={key}
              onClick={handleClose}>
              {label}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};

const MenuBar = ({
  MainMenu,
  defaultMenuSelectedKey,
  menuBarOnHide,
  movedUp,
}) => {
  console.log('main menu', MainMenu);
  let filteredMainMenu = [];
  const {menu_bar = []} = useSelector(getInstanceVersion) || {};

  if (menu_bar && menu_bar.length > 0) {
    filteredMainMenu =
      MainMenu && Array.isArray(MainMenu)
        ? MainMenu.filter(a => menu_bar.includes(a.key))
        : [];
  } else {
    filteredMainMenu = MainMenu && Array.isArray(MainMenu) ? MainMenu : [];
  }
  const matchKey =
    defaultMenuSelectedKey && defaultMenuSelectedKey.length === 2
      ? defaultMenuSelectedKey[1].split('/')[1]
      : defaultMenuSelectedKey[0];

  const value =
    defaultMenuSelectedKey &&
    defaultMenuSelectedKey[0] &&
    filteredMainMenu &&
    Array.isArray(filteredMainMenu)
      ? filteredMainMenu.map(a => a.key).indexOf(matchKey)
      : 0;

  return (
    <>
      <Slide
        appear={false}
        direction='down'
        in={!menuBarOnHide ? true : movedUp}>
        <StyledPaper square menuBarOnHide={menuBarOnHide} movedUp={movedUp}>
          <Box px={3}>
            <StyledTabs
              variant='scrollable'
              allowScrollButtonsMobile
              scrollButtons='auto'
              sx={{
                minHeight: menuHeight,
                height: menuHeight,
              }}
              value={value}
              aria-label='scrollable auto tabs example'>
              {Array.isArray(filteredMainMenu) &&
                filteredMainMenu.map(({key, label, path, children}) => {
                  const dynamicProps = {};
                  if (children)
                    dynamicProps['onClick'] = e => e.preventDefault();
                  return (
                    <StyledTab
                      key={key}
                      to={path}
                      exact={false}
                      label={
                        children ? (
                          <MenuDropDown
                            data={children}
                            label={label}
                            base={path}
                            selected={
                              defaultMenuSelectedKey
                                ? defaultMenuSelectedKey[0]
                                : false
                            }
                          />
                        ) : (
                          <StyledTabText
                            variant='subtitle2'
                            sx={{color: 'inherit'}}
                            fontSize={14}
                            textTransform={'initial'}>
                            {label}
                          </StyledTabText>
                        )
                      }
                      component={Link}
                      {...dynamicProps}
                    />
                  );
                })}
            </StyledTabs>
          </Box>
        </StyledPaper>
      </Slide>
      {/* {movedUp && menuBarOnHide && ( */}
      <Box
        sx={{height: menuHeight, minHeight: menuHeight, position: 'relative'}}
      />
      {/* )} */}
    </>
  );
};
export default MenuBar;
