import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {licenseTypeKeys} from 'config';
import {isArray, isEmpty} from 'lodash';
import {Box, Stack, Typography, useTheme, useMediaQuery} from '@mui/material';
import {
  Button,
  PathCard,
  Carousel,
  EventCard,
  CompanyCard,
  EducatorCard,
  ErrorBoundary,
  RequestErrorLoader,
  BusinessPartnerCard,
} from 'mui-core';
import {
  BusinessRounded,
  FactoryOutlined,
  PinDropOutlined,
  ArrowForwardIos,
  BusinessCenterOutlined,
} from '@mui/icons-material';
import {verifiedBlueCheckmark} from 'assets/images';

const CardCarousel = ({
  dataset,
  heading,
  description,
  redirectURL,
  cardType = 'path',
  slidesToShow = 4,
}) => {
  const theme = useTheme();
  const {request, data: pathData = {}} = dataset || {};
  const {path_details = []} = pathData || {};
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const errorCompLabel = useMemo(() => {
    switch (heading) {
      case 'New Programs': {
        return 'home-CardCarousel-newest';
      }
      case 'Popular Programs': {
        return 'home-CardCarousel-popular';
      }
      case 'In-Demand Programs': {
        return 'home-CardCarousel-inDemand';
      }
      default: {
        return 'home-CardCarousel';
      }
    }
  }, [heading]);

  const renderContent = useMemo(() => {
    switch (cardType) {
      case 'path': {
        return path_details?.map(path => {
          const isCriminalJustice =
            path?.institute_details?.super_type_name ===
            licenseTypeKeys?.criminalJustice;
          return (
            <Box key={path?.uuid} sx={{padding: '8px 16px'}}>
              <PathCard
                enableNavigation
                data={path || {}}
                hideLocation={isCriminalJustice}
                hideAwardType={!path?.award_type_name}
              />
            </Box>
          );
        });
      }
      case 'educator': {
        return path_details?.map(path => (
          <Box key={path?.uuid} sx={{padding: '8px 16px'}}>
            <EducatorCard enableNavigation data={path || {}} />
          </Box>
        ));
      }
      case 'event': {
        return path_details?.map(path => (
          <Box key={path?.uuid} sx={{padding: '8px 16px'}}>
            <EventCard enableNavigation data={path || {}} />
          </Box>
        ));
      }
      case 'businessPartner': {
        return path_details?.map(path => (
          <Box key={path?.uuid} sx={{padding: '8px 16px'}}>
            <BusinessPartnerCard enableNavigation data={path || {}} />
          </Box>
        ));
      }
      case 'company': {
        return path_details?.map(path => (
          <Box key={path?.job_id} sx={{padding: '8px 16px'}}>
            <CompanyCard
              enableNavigation
              verifiedIcon={
                path?.verification === 'Approved' ? verifiedBlueCheckmark : null
              }
              data={{
                path: path.company_url,
                companyName: path?.name,
                companyLogo: path?.logo_cloudinary,
                companyBanner: path?.banner_cloudinary,
                industrySector: {
                  industryName: path?.industry_sector,
                  IndustrySectorIcon: (
                    <BusinessRounded
                      sx={{color: theme.palette.primary.light}}
                    />
                  ),
                },
                physicalAddress: {
                  address: path?.physical_address,
                  LocationIcon: PinDropOutlined,
                },
              }}
              infoItems={[
                {
                  label: 'Jobs',
                  count: path?.active_jobs || 0,
                  Icon: BusinessCenterOutlined,
                },
                {
                  Icon: FactoryOutlined,
                  label: 'Apprenticeships',
                  count: path?.active_apprenticeships || 0,
                },
              ].filter(Boolean)}
            />
          </Box>
        ));
      }

      default: {
        return [];
      }
    }
  }, [cardType, path_details]);

  return (
    <ErrorBoundary nameOfComponent={errorCompLabel}>
      <RequestErrorLoader hideEmpty minHeight={100} body={dataset}>
        {!request &&
          path_details &&
          isArray(path_details) &&
          !isEmpty(path_details) && (
            <React.Fragment>
              <Stack
                mt={2}
                direction='row'
                alignItems='center'
                justifyContent={heading ? 'space-between' : 'flex-end'}>
                {heading && description && (
                  <Box>
                    {heading && (
                      <Typography variant='h4' color='text.primary'>
                        {heading}
                      </Typography>
                    )}
                    {description && (
                      <Typography mt={1} variant='body1' color='text.primary'>
                        {description}
                      </Typography>
                    )}
                  </Box>
                )}
                {heading && !description && (
                  <Typography variant='h4' color='text.primary'>
                    {heading}
                  </Typography>
                )}
                <Link to={redirectURL}>
                  <Button
                    endIcon={
                      <ArrowForwardIos
                        sx={{fontSize: '12px !important', mb: '4px'}}
                      />
                    }
                    sx={{
                      color:
                        theme.palette.mode === 'light'
                          ? 'primary.dark'
                          : 'primary.light',
                      padding: isMobile ? '4px 24px' : '8px 24px',
                    }}>
                    View All
                  </Button>
                </Link>
              </Stack>
              <Box my={4}>
                <Carousel slidesToShow={slidesToShow} content={renderContent} />
              </Box>
            </React.Fragment>
          )}
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

CardCarousel.propTypes = {
  heading: PropTypes.string,
  cardType: PropTypes.string,
  description: PropTypes.string,
  slidesToShow: PropTypes.number,
  dataset: PropTypes.object.isRequired,
  redirectURL: PropTypes.string.isRequired,
};

export default CardCarousel;
