import {Box, useTheme} from '@mui/material';
import {Grid, Button, CompanyCard, ErrorBoundary} from 'mui-core';
import {industryIconBlack, industryIconWhite} from 'assets/images';
import {apprenticeshipsListData} from 'data/apprenticeshipsList.json';
import {LocationOnOutlined, QueryBuilderOutlined} from '@mui/icons-material';

const ApprenticeshipsList = ({reqParams, onLoadMore, apprenticeshipsData}) => {
  const theme = useTheme();
  const {loadMoreBtnText} = apprenticeshipsListData;
  const {data: apprenticeshipsList, error, request} = apprenticeshipsData || {};
  const {data = [], page_details = {}} = apprenticeshipsList || {};
  const recordsCount = data?.length || 0;
  const totalCount = page_details?.total_results || 0;
  const hideLoadMore =
    (data?.page_details &&
      reqParams?.page >= data?.page_details?.page_size &&
      !request &&
      !error) ||
    recordsCount === totalCount ||
    totalCount <= 12;

  let defaultDisplay = true;
  if (request && !(reqParams?.page > 1)) {
    defaultDisplay = false;
  }

  const industryIcon =
    theme?.palette?.mode === 'light' ? industryIconBlack : industryIconWhite;

  return (
    <ErrorBoundary nameOfComponent='module-companies' typeOfUi='subPage'>
      {defaultDisplay && data?.length > 0 && (
        <>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{xs: 1, sm: 2}}
            justifyContent={{xs: 'center', sm: 'left'}}>
            {data?.map(
              ({
                job_id,
                banner,
                company,
                location,
                job_title,
                company_logo,
                program_length,
                institution_id,
                company_industry_sector,
              }) => {
                let path = `/apprenticeships/${encodeURIComponent(
                  company,
                )}/${encodeURIComponent(job_title)}`;
                return (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    xs={12}
                    sm='auto'
                    key={institution_id}
                    justifyContent='center'
                    sx={{textAlign: 'center'}}>
                    <CompanyCard
                      centeredLogo={false}
                      data={{
                        path: path,
                        title: job_title,
                        companyName: company,
                        companyBanner: banner,
                        companyLogo: company_logo,
                        industrySector: {
                          industryName: company_industry_sector,
                          IndustrySectorIcon: (
                            <img
                              width={18}
                              height={18}
                              src={industryIcon}
                              alt='industry icon'
                            />
                          ),
                        },
                      }}
                      width={{xs: 300, md: 320, lg: 360}}
                      infoItems={[
                        program_length && {
                          Icon: QueryBuilderOutlined,
                          value: program_length,
                        },
                        location && {
                          Icon: LocationOnOutlined,
                          value: location,
                        },
                      ].filter(Boolean)}
                      key={job_id}
                      target='_self'
                      enableNavigation
                    />
                  </Grid>
                );
              },
            )}
          </Grid>
          {!hideLoadMore && (
            <Box
              py={3}
              display='flex'
              alignContent='center'
              justifyContent='center'>
              <Button
                size='large'
                variant='outlined'
                onClick={onLoadMore}
                sx={{padding: '8px 24px'}}>
                {request ? loadMoreBtnText.loading : loadMoreBtnText.loadMore}
              </Button>
            </Box>
          )}
        </>
      )}
    </ErrorBoundary>
  );
};

export default ApprenticeshipsList;
