// hooks
import {useDispatch, useSelector} from 'react-redux';
import {useInstance, useOpportunitiesData} from 'core/hooks';
import {useState, useEffect, useCallback, useRef, useMemo} from 'react';
// components
import {
  FilterForm,
  LearnMoreAboutModal,
  ApprenticeshipsList,
} from './components';
import {Button} from '@mui/material';
import {LayoutContainer} from 'mui-core';
// redux
import {
  getLinkedInDetails,
  fetchlinkedInDetails,
} from 'redux/modules/resume-builder';
// utils
import {useTheme} from '@mui/material/styles';
import StaticBanner from 'mui-core/StaticBanner';
import {apprenticeshipsBanner} from 'assets/images';
import {apprenticeshipsListData} from 'data/apprenticeshipsList.json';

const {
  bannerData: {bannerTitle, bannerDesc, bannerButtonText},
  defaultLocation,
} = apprenticeshipsListData || {};

const sortArr = [
  {
    title: 'Newest',
    value: 'date_desc',
  },
  {
    title: 'A - Z',
    value: 'title_asc',
  },
  {
    title: 'Z - A',
    value: 'title_desc',
  },
];

const Apprenticeships = ({
  title = '',
  company = '',
  subTitle = '',
  onetCode = '',
  tabPage = false,
  showSort = true,
  showSearch = true,
  search_query = '',
  disableBGcolor = false,
}) => {
  const {
    data: {
      nearby_msa = [],
      super_type_name = null,
      default_msa: {city = '', state_code = ''} = {},
    } = {},
  } = useInstance();
  /* Commenting out as requirement changed for default location as Uinted state */
  // const defaultLoc = super_type_name
  //   ? `${city ? city + ', ' : ''}${state_code}`
  //   : defaultLocation;
  const defaultLoc = defaultLocation;
  const defaultParams = useMemo(() => {
    return {
      page: 1,
      page_size: 16,
      sort: 'date_desc',
      onet_code: onetCode,
    };
  }, [onetCode]);
  const theme = useTheme();
  const filterRef = useRef();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [showModal, setshowModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [reqParams, setReqParams] = useState(defaultParams);
  const [initialFilters, setInitialFilters] = useState(filters);
  const {
    apprenticeshipsData,
    clearApprenticeships,
    fetchAllApprenticeships,
  } = useOpportunitiesData();
  const linkedInData = useSelector(getLinkedInDetails);
  const {industry_name: industryList = []} = linkedInData;
  const {
    data: {data = [], page_details: {total_results = 0} = {}} = {},
    request: requestApprenticeships,
  } = apprenticeshipsData || {};
  const totalCount = total_results || 0;
  const recordsCount = data?.length || 0;

  useEffect(() => {
    let updatedFilters = {
      ...filters,
      location: defaultLoc,
      company_name: company,
    };
    if (search_query) {
      updatedFilters.title = search_query;
    }
    setInitialFilters(updatedFilters);
    refetchData(reqParams, updatedFilters);
    dispatch(fetchlinkedInDetails('industry_name', 'all'));
    return () => {
      clearApprenticeships();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onetCode) {
      let updatedParams = {
        ...reqParams,
        onet_code: onetCode,
      };
      refetchData(updatedParams, filters);
    }
  }, [onetCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const apiCall = (Obj, filters = null, loadMore = false) => {
    fetchAllApprenticeships(Obj, filters, loadMore);
  };

  const refetchData = (Obj, newFilters, loadMore) => {
    setReqParams(Obj);
    const updatedFilters = {...filters, ...newFilters};
    setFilters(updatedFilters);
    apiCall(Obj, updatedFilters, loadMore);
  };

  const onSortChange = value => {
    const Obj = Object.assign({}, reqParams, {sort: value, page: 1});
    refetchData(Obj, filters);
  };

  const onLoadMore = () => {
    const newPage = reqParams.page + 1;
    const Obj = Object.assign({}, reqParams, {
      page: newPage,
    });
    refetchData(Obj, filters, true);
  };

  const onFilterReset = resetObj => {
    const Obj = {...defaultParams};
    refetchData(Obj, {...resetObj});
    onDrawerClose();
  };

  const onFinish = useCallback(
    values => {
      let filterApply = false;
      if (values) {
        const filterKeys = Object.keys(values);
        filterKeys.forEach(key => {
          if (values[key] || values[key] === '') {
            filterApply = true;
          }
          if (key === 'no_of_days') {
            values[key] = parseInt(values[key]);
            if (values[key] === 0) {
              values[key] = null;
            }
            filterApply = true;
          }
        });
        if (filterApply) {
          const Obj = Object.assign({}, reqParams, {
            page: 1,
            sort: 'date_desc',
          });
          refetchData(Obj, values);
          onDrawerClose();
        }
      }
    },
    [reqParams, filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const modalHandler = value => {
    setshowModal(value);
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const dataSet = {
    data: data,
    request: requestApprenticeships,
  };

  return (
    <LayoutContainer
      title={title}
      dataSet={dataSet}
      subTitle={subTitle}
      showDivider={false}
      typeOfUi='fullPage'
      showSort={showSort}
      showSearch={showSearch}
      disableBGcolor={disableBGcolor}
      nameOfComponent='apprenticeships'
      banner={
        !tabPage ? (
          <>
            {/* <Container maxWidth='xl'>
              <AppBreadcrumb dataList={breadcrumbData} />
            </Container> */}
            <StaticBanner
              desc={bannerDesc}
              title={bannerTitle}
              url={apprenticeshipsBanner}>
              <Button
                p={1}
                variant='contained'
                onClick={() => setshowModal(true)}
                sx={{width: 'fit-content', marginTop: '32px !important'}}>
                {bannerButtonText}
              </Button>
            </StaticBanner>
          </>
        ) : null
      }
      searchProps={{
        filters,
        onFinish,
        onDrawerOpen,
        onFilterReset,
        name: 'title',
        onSearch: value => onFinish(value),
        searchPlaceholder: 'Search by apprenticeship title or other keywords',
      }}
      sortProps={{
        sortArr,
        onSortChange,
        sort: reqParams?.sort,
      }}
      searchResultProps={{
        totalCount: totalCount,
        recordsCount: recordsCount,
      }}
      filterForm={
        <FilterForm
          filters={filters}
          hideCompanyFilter={tabPage}
          initialFilters={initialFilters}
          onFinish={onFinish}
          nearbyMSA={nearby_msa?.map((l, idx) => {
            return {
              ...l,
              id: idx + 1,
              nearby_msa: 'Nearby Locations',
            };
          })}
          isDrawerOpen={isDrawerOpen}
          industryList={industryList}
          onDrawerClose={onDrawerClose}
          onFilterReset={onFilterReset}
        />
      }>
      <LearnMoreAboutModal modalHandler={modalHandler} showModal={showModal} />
      <ApprenticeshipsList
        tabPage={tabPage}
        reqParams={reqParams}
        onLoadMore={onLoadMore}
        apprenticeshipsData={apprenticeshipsData}
      />
    </LayoutContainer>
  );
};

export default Apprenticeships;
