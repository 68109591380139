import {useEffect, useState} from 'react';
// hooks
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// redux
import {getAppConfig} from 'redux/modules/general';
import {openLoginScreen} from 'redux/modules/auth';
// components
import {
  Box,
  Stack,
  Paper,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {LazyImage, StatsWithIcon} from 'mui-core';
import CarouselSlider from 'mui-core/Carousel/Carousel';
import {Engineering, BusinessCenter} from '@mui/icons-material';
// utils
import HOME_DATA from 'data/home';
import PropTypes from 'prop-types';
import {CLOUDINARY} from 'core/cloudinary';
import {noCollageLogo} from 'assets/images';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {alpha, styled, useTheme} from '@mui/material/styles';

const LogoContainer = styled(Paper)(({theme}) => ({
  zIndex: 1,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: 4,
  marginBottom: 8,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
  backgroundColor: theme.palette.background.paper,
  '& img': {
    height: '100%',
    padding: '2px',
    borderRadius: 4,
    maxWidth: '100%',
    objectFit: 'contain',
  },
  [theme.breakpoints.down('md')]: {
    width: 40,
    height: 40,
  },
}));

const BannerContent = styled(Box)(({isMobile, isExtraSmall}) => {
  let styles = {};
  if (!isMobile)
    styles = {
      minWidth: 0,
      width: 'auto',
      height: '100%',
      aspectRatio: '3 / 1',
      margin: 'auto',
    };
  let mobileStyles = {};
  if (isMobile && !isExtraSmall) {
    mobileStyles = {
      width: 'auto',
      height: '100%',
      aspectRatio: '2 / 1',
    };
  }
  let extraSmallStyles = {};
  if (isExtraSmall) {
    extraSmallStyles = {
      width: 'auto',
      height: '100%',
      aspectRatio: '4/ 3',
    };
  }
  return {
    display: 'flex',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ...styles,
    ...mobileStyles,
    ...extraSmallStyles,
  };
});

const StyledRibbon = styled(Paper)(({theme}) => ({
  zIndex: 1,
  bottom: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  backgroundColor: alpha(theme.palette.primary.darker, 0.8),
}));

const StyledPaper = styled(Paper)(({theme, darkMode}) => ({
  maxWidth: '60%',
  width: 'fit-content',
  padding: '16px 32px',
  backgroundColor: darkMode
    ? 'rgba(0, 0, 0, 0.87)'
    : theme.palette.background.paper,
  [theme.breakpoints.down('md')]: {
    padding: 12,
    maxWidth: '85%',
  },
  [theme.breakpoints.down('lg')]: {
    maxHeight: '330px',
  },
}));

const BylineText = styled(Typography)(({theme, isMobile}) => ({
  pt: 1,
  maxHeight: 116,
  lineHeight: isMobile ? 1.2 : 1.4,
  fontSize: isMobile ? '10px' : '15px',
  '& p:nth-child(1)': {
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    marginTop: '5px !important',
    marginBottom: '0px !important',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': isMobile ? '3' : '4',
  },
  '& p:nth-child(2)': {
    margin: '8px 0px !important',
  },
}));

const defaultBanner = (getBannerTitle, career) => [
  {
    byline_text: career.desc,
    banner_uuid: 'banner_uuid',
    headline_text: getBannerTitle(),
    banner_url: 'gps-graphics/banner/Homenewbanner_vfcabx.jpg',
  },
];

const CarouselBanner = ({
  instanceData,
  companyBanner,
  getBannerTitle,
  companyDetails,
}) => {
  //hooks
  const theme = useTheme();
  const history = useHistory();
  const appConfig = useSelector(getAppConfig);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  //vars
  const {subdomain, banner_details, company_details} = instanceData || {};
  const {data} = companyDetails || {};
  const dispatch = useDispatch();
  const {bp_details = []} = data || null || {};
  const {career} = HOME_DATA[appConfig?.lang || 'en'] || HOME_DATA['en'];
  const {
    carousalBanner: {
      ribbonText,
      buttonLabel,
      metricsTitle = [],
      featuredCompanyLabel,
    },
  } = HOME_DATA;

  //states
  const [banners, setBanners] = useState([]);

  const pathNavigateToCompany = (name, subdomain) => {
    const {href, protocol} = window.location;
    // let redirectURL = `${href}/${encodeURIComponent(subdomain || name)}`;
    let redirectURL = `/companies/${encodeURIComponent(subdomain || name)}`;
    let isCompanyConnected = false;
    // const compMatched = bp_details?.find(item => item.name === name); // Check whether the company is connected to the instance.
    if (subdomain) {
      isCompanyConnected = true;
    }
    // redirect to global instance.
    if (subdomain && !isCompanyConnected) {
      let globalURL = href.split('.').slice(1).join('.');
      redirectURL = `${protocol}//${globalURL}/${encodeURIComponent(
        subdomain || name,
      )}`;
    }
    window.open(redirectURL, '_self');
  };

  const onBylineTextClick = e => {
    const {target} = e;
    if (target && target.type === '[[signup]]') {
      dispatch(openLoginScreen({screen: 'signup'}));
      return;
    }
    if (target && target.type === '[[signin]]') {
      dispatch(openLoginScreen({screen: 'signin'}));
      return;
    }
    if (target && target.type === '[[programs]]') {
      history.push('/programs');
    }
    if (target && target.type === '[[companies]]') {
      history.push('/companies');
    }
    // preventDefault();
  };

  useEffect(() => {
    if (companyBanner && company_details && company_details.length > 0) {
      setBanners(company_details);
      return;
    }
    if (banner_details && banner_details.length > 0) {
      setBanners(banner_details);
    } else {
      setBanners(defaultBanner(getBannerTitle, career));
    }
  }, [banner_details, career, getBannerTitle, instanceData, setBanners]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{overflowX: 'hidden'}}>
      <CarouselSlider
        fullWidth
        autoplay
        config={{
          dots: true,
          speed: 1000,
          autoplay: true,
          lazyLoad: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 6500,
        }}
        data={banners.map(
          ({
            dark_mode,
            banner_url,
            banner_uuid,
            byline_text,
            active_jobs,
            headline_text,
            company_name,
            company_logo,
            physical_address,
            subdomain,
            active_apprenticeships,
          }) => {
            const address =
              physical_address?.state_code && physical_address?.city
                ? physical_address?.city + ', ' + physical_address?.state_code
                : '';

            const calculateStatsData = (jobs = 0, apprenticeships = 0) => {
              const Stats = [
                {
                  count: jobs >= 10000 ? '10,000+' : jobs || 0,
                  title: metricsTitle[0],
                  icon: (
                    <BusinessCenter sx={{color: 'white'}} fontSize='small' />
                  ),
                },
                {
                  title: metricsTitle[1],
                  count:
                    apprenticeships >= 10000 ? '10,000+' : apprenticeships || 0,
                  icon: <Engineering sx={{color: 'white'}} fontSize='small' />,
                },
              ];
              return Stats;
            };

            const metricsData = calculateStatsData(
              active_jobs,
              active_apprenticeships,
            );

            return (
              <BannerContent
                key={banner_uuid}
                isMobile={isMobile}
                isExtraSmall={isExtraSmall}
                sx={{
                  backgroundImage: `url(${CLOUDINARY.getBannerURL(
                    banner_url,
                  )})`,
                }}>
                {companyBanner ? (
                  <Box
                    pb={5}
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      backgroundColor: 'rgb(0 0 0 / 60%)',
                    }}>
                    <Container maxWidth='xl'>
                      <Stack
                        overflow='hidden'
                        direction='column'
                        alignItems='flex-start'
                        spacing={{xs: 1.5, xl: 3}}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                          <LogoContainer>
                            <LazyImage
                              alt='bp_logo'
                              src={company_logo}
                              imageType='cardLogo'
                              defaultImage={noCollageLogo}
                            />
                          </LogoContainer>
                          <Typography
                            color='white'
                            variant='bannerTitle'
                            sx={{textShadow: '0px 0px 3px grey'}}>
                            {company_name}
                          </Typography>
                        </Stack>
                        <Stack
                          spacing={0.5}
                          direction='row'
                          alignItems='center'>
                          <LocationOnIcon
                            sx={{
                              color: 'white',
                              marginBottom: '4px',
                              fontSize: {xs: 18, sm: 20, md: 24},
                            }}
                          />
                          <Typography
                            color='white'
                            variant='bodyXL'
                            textTransform='capitalize'
                            sx={{textShadow: '0px 0px 3px grey'}}>
                            {address}
                          </Typography>
                        </Stack>
                        <StatsWithIcon
                          data={metricsData}
                          countStyles={{textShadow: '0px 0px 3px grey'}}
                          titleStyles={{textShadow: '0px 0px 3px grey'}}
                        />
                        <Button
                          variant='contained'
                          onClick={() =>
                            pathNavigateToCompany(company_name, subdomain)
                          }>
                          {buttonLabel}
                        </Button>
                      </Stack>
                    </Container>
                    <StyledRibbon
                      sx={{
                        height: {xs: '80px', lg: '100px'},
                      }}>
                      <Container sx={{px: {xs: 2}}} maxWidth='xl'>
                        <Stack gap={2} direction='row' alignItems='center'>
                          <Typography
                            variant='h3'
                            color='white'
                            fontSize={{xs: '20px', sm: '25px', lg: '30px'}}
                            minWidth={{xs: '190px', sm: '228px', lg: '265px'}}>
                            {featuredCompanyLabel}
                          </Typography>
                        </Stack>
                      </Container>
                    </StyledRibbon>
                  </Box>
                ) : (
                  <>
                    {(headline_text || byline_text) && (
                      <Container>
                        <StyledPaper elevation={16} darkMode={dark_mode}>
                          <Typography
                            sx={{
                              height: 'auto',
                              fontWeight: 500,
                              lineHeight: 1.2,
                              overflow: 'hidden',
                              paddingBottom: '4px',
                              display: '-webkit-box',
                              textOverflow: 'ellipsis',
                              '-webkit-box-orient': 'vertical',
                              '-webkit-line-clamp': {xs: '2', md: '3'},
                              fontSize: {xs: 18, sm: 24, md: 32, lg: 45},
                              color: dark_mode ? 'white' : 'text.primary',
                              maxHeight: {
                                xs: '42px',
                                sm: '56px',
                                md: '110px',
                                lg: '168px',
                              },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: headline_text,
                            }}
                          />
                          <BylineText
                            component='p'
                            isMobile={isMobile}
                            onClick={onBylineTextClick}
                            color={dark_mode ? 'white' : 'text.primary'}
                            dangerouslySetInnerHTML={{
                              __html: byline_text,
                            }}
                          />
                          {/* <Stack direction='row' justifyContent='end'>
                  <Button>
                    <Typography color={'primary.main'} variant='h5'>
                      Create A free profir
                    </Typography>
                  </Button>
                </Stack> */}
                        </StyledPaper>
                      </Container>
                    )}
                  </>
                )}
              </BannerContent>
            );
          },
        )}
      />
    </Box>
  );
};

CarouselBanner.propTypes = {
  getBannerTitle: PropTypes.func,
  instanceData: PropTypes.object,
};

export default CarouselBanner;
