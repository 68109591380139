import {createTheme} from '@mui/material/styles';
import SearchResultsCounter from './SearchResultsCounter';
import CompaniesListSlider from './CompaniesListSlider';
import BusinessPartnerCard from './BusinessPartnerCard';
import NoBusinessPartners from './NoBusinessPartners';
import RequestErrorLoader from './RequestErrorLoader';
import NoContentNavigator from './NoContentNavigator';
import {themeColorMode, skillsLearned} from './utils';
import JobOpportunities from './JobOpportunities';
import RibbonNavigator from './RibbonNavigator';
import CompaniesBanner from './CompaniesBanner';
import StaticBanner from './StaticBanner';
import ResourceBanner from './ResourceBanner';
import CompanyCard from './CompanyCard';
import AutoComplete from './AutoComplete';
import LayoutContainer from './LayoutContainer';
import LinearBarGraph from './LinearBarGraph';
import {Button, ButtonTheme} from './Button';
import AppBreadcrumb from './AppBreadcrumb';
import ErrorBoundary from './ErrorBoundary';
import ProgramBanner from './ProgramBanner';
import EducatorCard from './EducatorCard';
import CareerBanner from './CareerBanner';
import CardCarousel from './CardCarousel';
import MetricsTiles from './MetricsTiles';
import Speedometer from './Speedometer';
import SkillsStack from './SkillsStack';
import ScrollReset from './ScrollReset';
import WrappedText from './WrappedText';
import ContentBox from './ContentBox';
import CareerCard from './CareerCard';
import DialogBox from './DialogBox';
import LazyImage from './LazyImage';
import Accordion from './Accordion';
import ErrorPage from './ErrorPage';
import CourseMap from './CourseMap';
import EventCard from './EventCard';
import AppFooter from './AppFooter';
import SubRouter from './SubRouter';
import NoResults from './NoResults';
import PathCard from './PathCard';
import MUIInput from './MUIInput';
import Carousel from './Carousel';
import DataGrid from './DataGrid';
import EmptyBox from './EmptyBox';
import BarChart from './BarChart';
import SkillsChip from './Chip';
import MUIList from './List';
import Stats from './Stats';
import Menu from './Menu';
import Grid from './Grid';
import Card from './Card';
import Faq from './FAQ';
import {TabPage} from './Tabs';
import Layout from './Layout';
import ThemeSwitch from './ThemeSwitch';
import AppHeader from './AppHeader';
import MenuBar from './AppHeader/MenuBar';
import BackgroundPaper from './BackgroundPaper';
import QualifyingPrograms from './QualifyingPrograms';
import AutoCompleteWrapper from './AutoCompleteWrapper';
import MultiSelectWithChip from './MultiSelectWithChip';
import PageLoader from './PageLoader';
import CareerInterestResults from './CareerInterestResults';
import LocationSearch from './LocationSearch';
import DebounceInput from './DebounceInput';
import ProgramCourseMap from './ProgramCourseMap';
import CarouselBanner from './CarouselBanner';
import CareerTrends from './CareerTrends';
import Stepper from './Stepper';
import Tooltip from './Tooltip';
import TextEditor from './TextEditor';
import HighLighter from './HighLighter';
import SkillsSelect from './SkillsSelect';
import JobTitleSelect from './JobTitleSelect';
import Confirm from './Confirm';
import VideoBanner from './VideoBanner';
import FilterDrawer from './FilterDrawer';
import SearchFilter from './SearchFilter';
import StatsWithIcon from './StatsWithIcon';
import LayoutStaticBanner from './LayoutStaticBanner';

const componentsTheme = createTheme({
  components: {
    MuiButton: ButtonTheme.components.MuiButton,
  },
});

export {
  Faq,
  Card,
  Grid,
  Menu,
  Stats,
  Button,
  MUIList,
  BarChart,
  EmptyBox,
  DataGrid,
  PathCard,
  Carousel,
  NoResults,
  LazyImage,
  EventCard,
  Accordion,
  ErrorPage,
  CourseMap,
  DialogBox,
  SubRouter,
  ContentBox,
  SkillsChip,
  CareerCard,
  SkillsStack,
  ScrollReset,
  WrappedText,
  FilterDrawer,
  AutoComplete,
  CardCarousel,
  EducatorCard,
  CareerBanner,
  StaticBanner,
  ProgramBanner,
  ErrorBoundary,
  skillsLearned,
  AppBreadcrumb,
  CompanyCard,
  ResourceBanner,
  themeColorMode,
  CompaniesBanner,
  componentsTheme,
  RibbonNavigator,
  JobOpportunities,
  QualifyingPrograms,
  NoContentNavigator,
  RequestErrorLoader,
  NoBusinessPartners,
  AutoCompleteWrapper,
  BusinessPartnerCard,
  CompaniesListSlider,
  SearchResultsCounter,
  SearchFilter,
  TabPage,
  Layout,
  MUIInput,
  AppFooter,
  Speedometer,
  ThemeSwitch,
  MetricsTiles,
  AppHeader,
  LinearBarGraph,
  BackgroundPaper,
  MultiSelectWithChip,
  MenuBar,
  PageLoader,
  CareerInterestResults,
  LocationSearch,
  DebounceInput,
  ProgramCourseMap,
  CarouselBanner,
  CareerTrends,
  Stepper,
  Tooltip,
  TextEditor,
  HighLighter,
  SkillsSelect,
  JobTitleSelect,
  Confirm,
  VideoBanner,
  StatsWithIcon,
  LayoutContainer,
  LayoutStaticBanner,
};
