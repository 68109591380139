import PropTypes from 'prop-types';
import {
  Box,
  Stack,
  Paper,
  styled,
  useTheme,
  Container,
  Typography,
} from '@mui/material';
import {LazyImage} from 'mui-core';
import {teal} from '@mui/material/colors';
import {LocationOn} from '@mui/icons-material';
import {noCollageLogo, defaultBannerImage} from 'assets/images';
const BannerContent = styled(Box)(
  ({theme, bgRatio = '3/1', bannerImage, backgroundPosition}) => ({
    width: 'auto',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    backgroundSize: 'cover',
    fontSize: {xs: 12, md: 24},
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      aspectRatio: bgRatio,
    },
    backgroundImage: `url(${bannerImage})`,
    backgroundPosition: backgroundPosition,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: '100%',
      aspectRatio: 'auto',
    },
  }),
);

const LogoContainer = styled(Paper)(({theme}) => ({
  zIndex: 1,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: 4,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
  backgroundColor: theme.palette.background.paper,
  '& img': {
    height: '100%',
    padding: '2px',
    borderRadius: 4,
    maxWidth: '100%',
    objectFit: 'contain',
  },
  [theme.breakpoints.down('md')]: {
    width: 40,
    height: 40,
  },
}));

const ContentBox = styled(Stack)(({theme, fullWidth}) => ({
  padding: '0px',
  width: fullWidth ? '100' : '60%',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: '36px 8px 42px',
  },
}));

const StyledRibbon = styled(Paper)(({theme}) => ({
  zIndex: 1,
  bottom: 0,
  width: '100%',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  backgroundColor: 'rgb(0 0 0 / 62%)',
  [theme.breakpoints.down('md')]: {
    height: '40px',
  },
}));

const StaticBanner = ({
  url,
  logo,
  bgRatio,
  children,
  ribbonTxt,
  desc = '',
  title = '',
  location = '',
  fullWidth = false,
  captionTitle = '',
  customRibbon = null,
  alignContent = 'left',
  descVariant = 'bodyL',
  backgroundPosition = 'top',
  titleVariant = 'bannerTitle',
  bgGradient = 'rgb(0 0 0 / 50%)',
}) => {
  //hooks
  const theme = useTheme();
  //vars
  const bannerImage = url || defaultBannerImage;

  return (
    <Box sx={{overflow: 'hidden'}}>
      <BannerContent
        bgRatio={bgRatio}
        bannerImage={bannerImage}
        sx={{position: 'relative'}}
        backgroundPosition={backgroundPosition}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            backgroundColor: bgGradient,
          }}>
          <Container maxWidth='xl'>
            <ContentBox
              spacing={2}
              fullWidth={fullWidth}
              alignItems={alignContent}
              justifyContent={alignContent}>
              {captionTitle && (
                <Typography
                  variant='bodyXL'
                  color={teal['A400']}
                  mb={{xs: 0, md: 3.5}}
                  textTransform='uppercase'>
                  {captionTitle}
                </Typography>
              )}
              <Stack direction='row' gap={2}>
                {logo && (
                  <LogoContainer>
                    <LazyImage
                      src={logo}
                      alt='bp_logo'
                      imageType='logo'
                      defaultImage={noCollageLogo}
                    />
                  </LogoContainer>
                )}
                <Typography
                  color='white'
                  variant={titleVariant}
                  textTransform='capitalize'
                  textAlign={alignContent ? alignContent : 'left'}>
                  {title}
                </Typography>
              </Stack>
              {desc && (
                <Typography
                  color='white'
                  variant={descVariant}
                  sx={{mb: '8px', mt: '16px !important'}}
                  dangerouslySetInnerHTML={{__html: desc}}
                  textAlign={alignContent ? alignContent : 'left'}
                />
              )}
              {location && (
                <Stack direction='row' alignItems='center' spacing={1}>
                  <LocationOn
                    sx={{
                      color: 'white',
                      marginBottom: '4px',
                      fontSize: {xs: 18, sm: 20, md: 24},
                    }}
                  />
                  <Typography color='white' variant='bodyXL'>
                    {location}
                  </Typography>
                </Stack>
              )}
              {children}
            </ContentBox>
          </Container>
        </Box>
        {customRibbon}
        {ribbonTxt && (
          <StyledRibbon>
            <Container maxWidth='xl'>
              <Typography variant='h4' color='white'>
                {ribbonTxt}
              </Typography>
            </Container>
          </StyledRibbon>
        )}
      </BannerContent>
    </Box>
  );
};

StaticBanner.propTypes = {
  url: PropTypes.string,
  logo: PropTypes.string,
  desc: PropTypes.string,
  title: PropTypes.string,
  bgRatio: PropTypes.string,
  location: PropTypes.string,
  children: PropTypes.element,
  ribbonTxt: PropTypes.string,
};

export default StaticBanner;
